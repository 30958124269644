/* eslint-disable no-undef */
import React from 'react';
import { withProps, compose } from 'recompose';
import styled, { keyframes } from 'styled-components';
import {
  withGoogleMap,
  withScriptjs,
  GoogleMap,
  Marker,
} from 'react-google-maps';
import markerIcon from 'assets/icons/pin.png';
import institutionIcon from 'assets/icons/Instytucje.svg';
import scienceIcon from 'assets/icons/Nauka.svg';
import parksIcon from 'assets/icons/Parki.svg';
import entertainmentIcon from 'assets/icons/Rozrywka.svg';
import sportIcon from 'assets/icons/Sport.svg';
import transportIcon from 'assets/icons/Transport.svg';
import restaurantsIcon from 'assets/icons/Restauracje.svg';
import shopsIcon from 'assets/icons/Sklep.svg';

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const mapStyles = [
  {
    featureType: 'road.highway',
    stylers: [
      {
        hue: '#FFC200',
      },
      {
        saturation: -61.8,
      },
      {
        lightness: 45.599999999999994,
      },
      {
        gamma: 1,
      },
    ],
  },
  {
    featureType: 'road.local',
    stylers: [
      {
        hue: '#FF0300',
      },
      {
        saturation: -100,
      },
      {
        lightness: 52,
      },
      {
        gamma: 1,
      },
    ],
  },
  {
    featureType: 'water',
    stylers: [
      {
        hue: '#0078FF',
      },
      {
        saturation: -13.200000000000003,
      },
      {
        lightness: 2.4000000000000057,
      },
      {
        gamma: 1,
      },
    ],
  },
];

const Wrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.colors.white};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Loader = styled.figure`
  display: block;
  width: 50px;
  height: 50px;
  border: 4px solid ${({ theme }) => theme.colors.green};
  border-top: 4px solid transparent;
  border-bottom: 4px solid transparent;
  border-radius: 50%;
  animation: ${spin} 1s ease infinite;
`;

const MapLoader = () => (
  <Wrapper>
    <Loader />
  </Wrapper>
);

const getIcon = type => {
  switch (type.toLowerCase()) {
    case 'transport':
      return transportIcon;
    case 'parki':
      return parksIcon;
    case 'nauka':
      return scienceIcon;
    case 'rozrywka':
      return entertainmentIcon;
    case 'instytucje':
      return institutionIcon;
    case 'sport':
      return sportIcon;
    case 'restauracje':
      return restaurantsIcon;
    case 'sklepy':
      return shopsIcon;
    default:
      return null;
  }
};

const MapContainer = compose(
  withProps({
    googleMapURL:
      'https://maps.googleapis.com/maps/api/js?key=AIzaSyBEgHBuTGoztjxYaQnyb3fQGYHPA9X1s9c&v=3.exp&libraries=geometry,drawing,places',
    loadingElement: <MapLoader />,
    containerElement: <div style={{ height: `100%` }} />,
    mapElement: <div style={{ height: `100%` }} />,
  }),
  withScriptjs,
  withGoogleMap
)(props => (
  <GoogleMap
    defaultZoom={15}
    defaultCenter={{ lat: props.lat, lng: props.lng }}
    defaultOptions={{ styles: mapStyles }}
  >
    <Marker
      zIndex={6}
      position={{
        lat: props.lat,
        lng: props.lng,
      }}
      onMouseOver={() => props.setCurrentLocation('Żeromskiego 17')}
      onMouseOut={() => props.setCurrentLocation('')}
      title="Żeromskiego 17"
      icon={{
        url: markerIcon,
        anchor: new google.maps.Point(50, 121),
        scaledSize: new google.maps.Size(100, 121),
      }}
    />
    {props.locations &&
      props.locations.map(
        ({
          locMapLocationLat,
          locMapLocationLng,
          locMapLocationName,
          locMapLocationType,
          isMarkerShown,
        }) => (
          <React.Fragment key={`${locMapLocationLat} + ${locMapLocationLng}`}>
            {isMarkerShown && (
              <Marker
                onMouseOver={() => props.setCurrentLocation(locMapLocationName)}
                onMouseOut={() => props.setCurrentLocation('')}
                zIndex={5}
                position={{
                  lat: Number(locMapLocationLat),
                  lng: Number(locMapLocationLng),
                }}
                title={locMapLocationName}
                icon={{
                  url: getIcon(locMapLocationType),
                  anchor: new google.maps.Point(24, 48),
                  scaledSize: new google.maps.Size(48, 48),
                }}
              />
            )}
          </React.Fragment>
        )
      )}
  </GoogleMap>
));

export default MapContainer;
