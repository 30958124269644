import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import SEO from 'components/shared/SEO';
import Popup from 'components/shared/Popup';
import Modal from 'components/Modal/Modal';
import Header from 'components/Header/Header';
import AskAboutFlat from 'components/AskAboutFlat/AskAboutFlat';
import Details from 'components/About/About';
import Flats from 'components/Flats/Flats';
import Localization from 'components/Localization/Localization';
import Navigation from 'components/Navigation/Navigation';
import Contact from 'components/Contact/Contact';
import Credit from 'components/Credit/Credit';
import Gallery from 'components/Gallery/Gallery';
import Announcment from 'components/Announcment/Announcment';
import manIcon from 'assets/icons/man.svg';
import womanIcon from 'assets/icons/woman.svg';
import Footer from 'components/Footer/Footer';
import Cookies from 'components/Cookies/Cookies';
import LazyLoad from 'react-lazyload';

const IndexPage = ({ data }) => {
  const HOMEPAGE = data.wpgraphql.page.PageHome;

  const popupImg =
    HOMEPAGE.promoPopup && HOMEPAGE.promoPopup.imageFile.childImageSharp.fluid;
  const heroImage = HOMEPAGE.bannerImage1.imageFile.childImageSharp.fluid;
  const headerImg = HOMEPAGE.bannerImage2.imageFile.childImageSharp.fluid;
  const detailsImg = HOMEPAGE.detailsImage.imageFile.childImageSharp.fluid;
  const flatsBg = HOMEPAGE.flatsBg.imageFile.childImageSharp.fluid;
  const galleryBg = HOMEPAGE.galleryBg.imageFile.childImageSharp.fluid;
  const announcementBg =
    HOMEPAGE.announcementBg.imageFile.childImageSharp.fluid;

  const creditTeam = useMemo(
    () =>
      HOMEPAGE.creditTeam.map(
        ({
          creditTeamEmail,
          creditTeamGender,
          creditTeamName,
          creditTeamPhone,
          creditTeamRole,
        }) => ({
          icon: creditTeamGender === 'man' ? manIcon : womanIcon,
          name: creditTeamName,
          job: creditTeamRole,
          phone: creditTeamPhone,
          email: creditTeamEmail,
        })
      ),
    []
  );

  const galleryAlbums = useMemo(
    () =>
      HOMEPAGE.galleryAlbums.map(
        ({ galleryAlbumsTitle, galleryAlbumsImages }) => ({
          title: galleryAlbumsTitle,
          images: galleryAlbumsImages.map(({ galleryAlbumsImagesImg }) => ({
            src: galleryAlbumsImagesImg.imageFile.childImageSharp.fluid,
            alt: `${galleryAlbumsImagesImg.title}`,
          })),
        })
      ),
    []
  );

  const openingHours = useMemo(
    () =>
      HOMEPAGE.contactHours.map(
        ({ contactHoursName, contactHoursFrom, contactHoursTo }) => ({
          day: contactHoursName,
          openingTime: contactHoursFrom,
          closingTime: contactHoursTo,
        })
      ),
    []
  );

  const locAdvantages = useMemo(
    () =>
      HOMEPAGE.locAdvantages.map(({ locAdvantageText, locAdvantageIcon }) => ({
        icon: locAdvantageIcon.sourceUrl,
        text: locAdvantageText,
      })),
    []
  );

  return (
    <>
      {popupImg && <Popup popupImg={popupImg} />}
      <Modal />
      <Navigation />
      <SEO title="Strona główna" />
      <Header
        image={heroImage}
        image2={headerImg}
        imageAlt="hero image"
        description={HOMEPAGE.bannerText}
      />
      <main>
        <AskAboutFlat />
        <Localization
          locations={HOMEPAGE.locMapLocations}
          content={HOMEPAGE.locText}
          lat={Number(HOMEPAGE.locMapLat)}
          lng={Number(HOMEPAGE.locMapLng)}
          descriptionOne={HOMEPAGE.locText1}
          descriptionTwo={HOMEPAGE.locText2}
          promoItems={locAdvantages}
        />
        <Flats
          heading={HOMEPAGE.flatsHeading}
          description={HOMEPAGE.flatsText}
          image={flatsBg}
          imageAlt="background"
        />
        <Credit
          heading={HOMEPAGE.creditHeading}
          description={HOMEPAGE.creditText}
          teamHeading={HOMEPAGE.creditTitle}
          team={creditTeam}
        />
        <Details
          image={detailsImg}
          description={HOMEPAGE.detailsText}
          packets={HOMEPAGE.detailsOptions.map(
            ({ detailsOptionText }) => detailsOptionText
          )}
        />
        <Gallery
          heading={HOMEPAGE.galleryHeading}
          image={galleryBg}
          albums={galleryAlbums}
        />
        <Contact
          title={HOMEPAGE.contactHeading}
          address={HOMEPAGE.contactAddress}
          content={HOMEPAGE.contactText}
          phone={HOMEPAGE.contactPhone}
          email={HOMEPAGE.contactEmail}
          mapCords={{
            lat: Number(HOMEPAGE.contactMapLat),
            lng: Number(HOMEPAGE.contactMapLng),
          }}
          infos={
            HOMEPAGE.contactInfo
              ? HOMEPAGE.contactInfo.map(
                  ({ contactInfoInfo }) => contactInfoInfo
                )
              : ['']
          }
          openingHours={openingHours}
          pdf={{
            text: HOMEPAGE.promoPdfText,
            link: HOMEPAGE.promoPdfFile && HOMEPAGE.promoPdfFile.link,
          }}
        />
        <LazyLoad offset={500} height={500} once>
          <Announcment
            heading={HOMEPAGE.announcementHeading}
            description={HOMEPAGE.announcementContent}
            image={announcementBg}
            messengerID={HOMEPAGE.contactMessenger}
            videoURL={HOMEPAGE.announcementVideo}
          />
        </LazyLoad>
      </main>
      <LazyLoad offset={150} height={500} once>
        <Footer />
      </LazyLoad>
      <Cookies />
    </>
  );
};

IndexPage.propTypes = {
  data: PropTypes.objectOf(PropTypes.object).isRequired,
};

export const query = graphql`
  query queryHomePage {
    wpgraphql {
      page(id: "/strona-glowna/", idType: URI) {
        PageHome {
          promoPdfText
          promoPdfFile {
            link
          }
          promoPopup {
            sourceUrl
            imageFile {
              childImageSharp {
                fluid(maxWidth: 600) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          bannerText
          bannerImage1 {
            sourceUrl
            imageFile {
              childImageSharp {
                fluid(maxWidth: 1165, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          bannerImage2 {
            sourceUrl
            imageFile {
              childImageSharp {
                fluid(maxWidth: 768) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          flatsHeading
          flatsText
          flatsBg {
            sourceUrl
            imageFile {
              childImageSharp {
                fluid(maxWidth: 1920, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          creditTitle
          creditText
          creditHeading
          creditTeam {
            creditTeamEmail
            creditTeamGender
            creditTeamName
            creditTeamPhone
            creditTeamRole
            fieldGroupName
          }
          contactInfo {
            contactInfoInfo
          }
          contactMapLat
          contactMapLng
          contactMessenger
          locText1
          locText2
          locMapLat
          locMapLng
          locAdvantages {
            locAdvantageText
            locAdvantageIcon {
              sourceUrl
            }
          }
          locMapLocations {
            locMapLocationLat
            locMapLocationLng
            locMapLocationName
            locMapLocationType
          }
          galleryHeading
          galleryAlbums {
            galleryAlbumsTitle
            galleryAlbumsImages {
              galleryAlbumsImagesImg {
                sourceUrl
                imageFile {
                  childImageSharp {
                    fluid(maxWidth: 1920, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
          galleryBg {
            sourceUrl
            imageFile {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          detailsText
          detailsOptions {
            detailsOptionText
          }
          detailsImage {
            sourceUrl
            imageFile {
              childImageSharp {
                fluid(maxWidth: 1000) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          contactPhone
          contactHeading
          contactEmail
          contactText
          contactAddress
          contactHours {
            contactHoursFrom
            contactHoursName
            contactHoursTo
          }
          announcementContent
          announcementHeading
          announcementVideo
          announcementBg {
            sourceUrl
            imageFile {
              childImageSharp {
                fluid(maxWidth: 2000) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default IndexPage;
