/* eslint-disable react/prop-types */
import React, { useState, useEffect, useRef } from 'react';
import Icon from 'components/shared/Icon';
import LazyImage from 'components/shared/LazyImage';
import styled, { css } from 'styled-components';
import Cookies from 'js-cookie';
import useOutsideClick from 'hooks/useOutsideClick';
import scrollToSection from 'utils/scrollToSection';
import closeIcon from 'assets/icons/close.svg';

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s;
  ${({ visible }) =>
    visible &&
    css`
      opacity: 1;
      visibility: visible;
    `}
`;

const Wrapper = styled.div`
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -35%);
  visibility: hidden;
  opacity: 0;
  transition: 0.35s ease;
  cursor: pointer;
  ${({ visible }) =>
    visible &&
    css`
      opacity: 1;
      visibility: visible;
      transform: translate(-50%, -50%);
    `}
`;

const InnerWrapper = styled.div`
  position: relative;
`;

const CloseButton = styled.button`
  background: transparent;
  padding: 0;
  margin: 0;
  position: absolute;
  top: 20px;
  right: 20px;
  width: 20px;
  height: 20px;
  z-index: 1;
  cursor: pointer;
  ${({ theme }) => theme.mq.xs} {
    width: 25px;
    height: 25px;
  }
`;

const IconWrapper = styled.div`
  min-width: 300px;
  & > div {
    width: 300px !important;
    height: 300px !important;
  }
  @media (min-height: 500px) {
    ${({ theme }) => theme.mq.xs} {
      min-width: 400px;
      & > div {
        width: 400px !important;
        height: 400px !important;
        ${({ theme }) => theme.mq.lg} {
          width: 600px !important;
          height: 600px !important;
        }
      }
    }
  }
`;

const Popup = ({ popupImg }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [scrolling, setScrolling] = useState(false);

  const closePopup = () => {
    Cookies.set('zeromskiego-popup', true, { expires: 7 });
    setIsVisible(false);
  };

  useEffect(() => {
    const isAccepted = Cookies.get('zeromskiego-popup');
    if (!isAccepted) {
      setIsVisible(true);
    }
  }, []);

  useEffect(() => {
    const scrollToPromoFlats = () => {
      const flatsSection = document.querySelector(`#flats-browser`);
      if (flatsSection && scrolling) {
        flatsSection.scrollIntoView({ behavior: 'smooth' });
        setScrolling(false);
      }
    };

    window.addEventListener('scroll', scrollToPromoFlats);
    return () => window.removeEventListener('scroll', scrollToPromoFlats);
  }, [scrolling]);

  const popupRef = useRef(null);
  useOutsideClick(popupRef, closePopup);

  return (
    <Overlay visible={isVisible}>
      <Wrapper
        onClick={() => {
          scrollToSection('#search-3d-anchor');
          closePopup();
          setScrolling(true);
        }}
        ref={popupRef}
        visible={isVisible}
      >
        <InnerWrapper>
          <CloseButton onClick={closePopup}>
            <Icon src={closeIcon} alt="zamknij" full />
          </CloseButton>
          <IconWrapper>
            <LazyImage src={popupImg} alt="promocja" />
          </IconWrapper>
        </InnerWrapper>
      </Wrapper>
    </Overlay>
  );
};

export default Popup;
