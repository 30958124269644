import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Content from 'components/shared/Content';
import Section from 'components/shared/Section';
import Icon from 'components/shared/Icon';
import Map from 'components/Localization/Map';
import LazyLoad from 'react-lazyload';
import useMedia from 'hooks/useMedia';
import institutionIcon from 'assets/icons/Instytucje.svg';
import scienceIcon from 'assets/icons/Nauka.svg';
import parksIcon from 'assets/icons/Parki.svg';
import entertainmentIcon from 'assets/icons/Rozrywka.svg';
import sportIcon from 'assets/icons/Sport.svg';
import transportIcon from 'assets/icons/Transport.svg';
import restaurantsIcon from 'assets/icons/Restauracje.svg';
import shopsIcon from 'assets/icons/Sklep.svg';

const StyledSection = styled(Section)`
  padding-top: 20px !important;
  padding-bottom: 0 !important;
  background: #dbf2fb;
`;

const Wrapper = styled.div`
  padding: 20px 0;
  ${({ theme }) => theme.mq.xs} {
    padding: 0 0 20px;
  }
  ${({ theme }) => theme.mq.s} {
    padding: 0;
  }
`;

const InnerWrapper = styled.div`
  display: flex;
  color: ${({ theme }) => theme.colors.dark};
  flex-direction: column;
`;

const StyledContent = styled(Content)`
  position: relative;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
`;

const MapWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 300px;
  padding-bottom: 15px;
  ${({ theme }) => theme.mq.xs} {
    height: 400px;
  }
  ${({ theme }) => theme.mq.lg} {
    width: 100%;
    height: 800px;
  }
`;

const Heading = styled.h2`
  display: block;
  font-size: 2.8rem;
  padding-bottom: 15px;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
  color: #00a6e2;
  ${({ theme }) => theme.mq.xs} {
    font-size: 3.2rem;
  }
`;

const Options = styled.div`
  position: absolute;
  top: 55%;
  left: 0;
  transform: translateY(-55%);
  border-radius: 0 20px 20px 0;
  width: 70px;
  padding: 10px 0;
  background: #fff;
  box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 2px solid ${({ theme }) => theme.colors.green};
  border-left: none;
`;

const MarkerButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  width: 52px;
  height: 52px;
  margin: 8px 0;
  padding: 0;
  cursor: pointer;
  transition: 0.3s;
  opacity: ${({ visible }) => (visible ? '1' : '0.3')};
  &:hover {
    opacity: 1;
  }
`;

const Topbar = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 0 0 20px 20px;
  height: 60px;
  background: #fff;
  box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 2px solid ${({ theme }) => theme.colors.green};
  border-top: none;
  padding: 20px 30px;
`;

const PromoIcon = styled.div`
  min-width: 50px;
  min-height: 50px;
`;

const Text = styled.p`
  font-size: 1.6rem;
  line-height: 1.5;
  font-weight: ${({ theme }) => theme.light};
  margin-left: 20px;
  ${({ theme }) => theme.mq.s} {
    font-size: 1.8rem;
  }
`;

const LocationName = styled.h2`
  font-size: 2.2rem;
  font-weight: ${({ theme }) => theme.light};
  color: ${({ theme }) => theme.colors.green};
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 25px 0;
  ${({ theme }) => theme.mq.s} {
    padding: 40px 0;
  }
`;

const PromoItem = styled.div`
  display: flex;
  padding: 10px 0;
`;

const Description = styled(Text)`
  em {
    color: ${({ theme }) => theme.colors.blue};
    font-weight: 700;
  }
  sup {
    font-weight: 700;
    color: #000;
  }
  margin: 15px 0;
`;

const Localization = ({
  lat,
  lng,
  locations,
  descriptionOne,
  descriptionTwo,
  promoItems,
}) => {
  const allLocations =
    locations &&
    locations.map(location => ({
      ...location,
      isMarkerShown: true,
    }));

  const matchesLG = useMedia('(min-width: 992px)');

  const [activeLocations, setActiveLocations] = useState(allLocations);
  const [currentLocation, setCurrentLocation] = useState('');
  const [visibleTypes, setVisibleMarkers] = useState([
    'Instytucje',
    'Nauka',
    'Parki',
    'Rozrywka',
    'Sport',
    'Transport',
    'Restauracje',
    'Sklepy',
  ]);

  const handleBtnClick = option => {
    if (visibleTypes.includes(option)) {
      setVisibleMarkers(visibleTypes.filter(item => item !== option));
    } else setVisibleMarkers([...visibleTypes, option]);
  };

  useEffect(() => {
    const newLocations =
      allLocations &&
      allLocations.filter(({ locMapLocationType }) =>
        visibleTypes.includes(locMapLocationType)
      );

    setActiveLocations(newLocations);
  }, [visibleTypes]);

  return (
    <StyledSection id="localization">
      <Wrapper>
        <StyledContent>
          <InnerWrapper>
            <ContentWrapper>
              <Heading>Lokalizacja</Heading>
              <Description
                dangerouslySetInnerHTML={{ __html: descriptionOne }}
              />
              {promoItems.map(({ icon, text }) => (
                <PromoItem key={text}>
                  <PromoIcon>
                    <Icon src={icon} alt="icon" size={50} />
                  </PromoIcon>
                  <Text dangerouslySetInnerHTML={{ __html: text }} />
                </PromoItem>
              ))}
              <Description
                dangerouslySetInnerHTML={{ __html: descriptionTwo }}
              />
            </ContentWrapper>
            <LazyLoad height={800} once>
              <MapWrapper>
                <Map
                  setCurrentLocation={setCurrentLocation}
                  locations={activeLocations}
                  lat={lat}
                  lng={lng}
                />
                {Boolean(matchesLG && locations) && (
                  <>
                    <Options>
                      <MarkerButton
                        onClick={() => handleBtnClick('Instytucje')}
                        visible={visibleTypes.includes('Instytucje')}
                      >
                        <Icon src={institutionIcon} alt="instytucje" full />
                      </MarkerButton>
                      <MarkerButton
                        onClick={() => handleBtnClick('Nauka')}
                        visible={visibleTypes.includes('Nauka')}
                      >
                        <Icon src={scienceIcon} alt="nauka" full />
                      </MarkerButton>
                      <MarkerButton
                        onClick={() => handleBtnClick('Parki')}
                        visible={visibleTypes.includes('Parki')}
                      >
                        <Icon src={parksIcon} alt="parki" full />
                      </MarkerButton>
                      <MarkerButton
                        onClick={() => handleBtnClick('Rozrywka')}
                        visible={visibleTypes.includes('Rozrywka')}
                      >
                        <Icon src={entertainmentIcon} alt="rozrywka" full />
                      </MarkerButton>
                      <MarkerButton
                        onClick={() => handleBtnClick('Sport')}
                        visible={visibleTypes.includes('Sport')}
                      >
                        <Icon src={sportIcon} alt="sport" full />
                      </MarkerButton>
                      <MarkerButton
                        onClick={() => handleBtnClick('Transport')}
                        visible={visibleTypes.includes('Transport')}
                      >
                        <Icon src={transportIcon} alt="transport" full />
                      </MarkerButton>
                      <MarkerButton
                        onClick={() => handleBtnClick('Restauracje')}
                        visible={visibleTypes.includes('Restauracje')}
                      >
                        <Icon src={restaurantsIcon} alt="restauracje" full />
                      </MarkerButton>
                      <MarkerButton
                        onClick={() => handleBtnClick('Sklepy')}
                        visible={visibleTypes.includes('Sklepy')}
                      >
                        <Icon src={shopsIcon} alt="sklepy" full />
                      </MarkerButton>
                    </Options>
                    {currentLocation && (
                      <Topbar>
                        <LocationName>{currentLocation}</LocationName>
                      </Topbar>
                    )}
                  </>
                )}
              </MapWrapper>
            </LazyLoad>
          </InnerWrapper>
        </StyledContent>
      </Wrapper>
    </StyledSection>
  );
};

Localization.propTypes = {
  locations: PropTypes.arrayOf(PropTypes.object).isRequired,
  lat: PropTypes.number.isRequired,
  lng: PropTypes.number.isRequired,
  descriptionOne: PropTypes.string.isRequired,
  descriptionTwo: PropTypes.string.isRequired,
  promoItems: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Localization;
