/* eslint-disable no-param-reassign */
import React, { useState, useEffect, useRef } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import Button from 'components/shared/Button';
import checkIcon from 'assets/icons/confirm.svg';
import errorIcon from 'assets/icons/close-red.svg';
import getFormParams from 'browser/utils/getFormParams';
import getCookie from 'browser/utils/getCookie';

const StyledForm = styled(Form)`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: visible;
  color: ${({ theme }) => theme.colors.dark};
  ${({ theme }) => theme.mq.xl} {
    max-width: 750px;
  }
`;

const Input = styled.input`
  width: 100%;
  padding: 15px 20px;
  margin-bottom: 15px;
  color: ${({ theme, error }) =>
    error ? theme.colors.red100 : theme.colors.dark};
  font-size: 1.6rem;
  font-family: ${({ theme }) => theme.fonts.mainFont};
  overflow: visible;
  transition: 0.3s;
  border: 1px solid ${({ theme }) => theme.colors.gray100};
  &:focus {
    color: ${({ theme }) => theme.colors.dark} !important;
    border-color: ${({ theme }) => theme.colors.green} !important;
  }
  &::placeholder {
    opacity: 0.8;
    color: ${({ theme }) => theme.colors.dark};
  }
  ${({ error }) =>
    error &&
    css`
      border-color: ${({ theme }) => theme.colors.red100};
    `}
`;

const Textarea = styled(Input)`
  flex: 1;
  resize: none;
  font-family: inherit;
  font-weight: ${({ theme }) => theme.light};
  min-height: 120px;
`;

const Label = styled.label`
  display: flex;
  align-items: center;
  min-height: 25px;
  position: relative;
  padding-left: 40px;
  margin: 15px 0;
  cursor: pointer;
  font-weight: ${({ theme }) => theme.light};
  font-size: ${({ theme }) => theme.fontSize.xs};
  user-select: none;
  line-height: 18px;
  ${({ theme }) => theme.mq.xs} {
    padding-left: 50px;
    font-size: ${({ theme }) => theme.fontSize.s};
  }
`;

const RoomLabel = styled(Label)`
  padding-left: 30px !important;
  font-size: 1.7rem !important;
  margin: 10px 20px 15px 0;
  ${({ theme }) => theme.mq.xs} {
    margin: 15px 20px 20px 10px;
    padding-left: 35px !important;
  }
`;

const RoomText = styled.p`
  position: absolute;
  top: 6px;
`;

const LabelBtn = styled.button`
  background: transparent;
  color: ${({ theme }) => theme.colors.dark};
  font-weight: ${({ theme }) => theme.bold};
  font-size: ${({ theme }) => theme.fontSize.xs};
  font-family: ${({ theme }) => theme.fonts.mainFont};
  text-transform: uppercase;
  cursor: pointer;
  align-self: flex-start;
  padding: 2px 0 2px 10px;
  margin-left: auto;
`;

const LabelText = styled.p`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  text-align: justify;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  font-weight: ${({ theme }) => theme.light};
  ${({ expanded }) =>
    expanded &&
    css`
      display: block;
      text-overflow: clip;
      -webkit-line-clamp: none;
    `}
`;

const Checkmark = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background: ${({ theme }) => theme.colors.white};
  border: 1px solid;
  border-color: ${({ theme, error }) =>
    error ? theme.colors.red100 : theme.colors.gray100};
  &:after {
    content: '';
    position: absolute;
    display: none;
    left: 4px;
    top: 4px;
    width: 15px;
    height: 15px;
    background: url(${checkIcon}) no-repeat center;
  }
`;

const Checkbox = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
  &:checked ~ ${Checkmark}:after {
    display: block;
  }
`;

const FormFooter = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 20px 0 0;
  font-weight: ${({ theme }) => theme.light};
  & > button {
    margin: 0 auto;
  }
  ${({ theme }) => theme.mq.md} {
    flex-direction: row;
    justify-content: space-between;
    & > button {
      margin: 0 0 0 auto;
    }
  }
`;

const ErrorsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  & > span {
    margin-bottom: 8px;
    &:last-child {
      margin-bottom: 25px;
    }
  }
`;

const ErrorMessage = styled.span`
  display: flex;
  font-size: ${({ theme }) => theme.fontSize.m};
  color: ${({ theme }) => theme.colors.red100};
  line-height: 18px;
  &:before {
    content: '';
    margin-top: 1px;
    margin-right: 10px;
    width: 12px;
    height: 12px;
    background: url(${errorIcon}) no-repeat center;
    background-size: 12px 12px;
  }
`;

const MessageContent = styled(ErrorMessage)`
  opacity: ${({ color }) => (color ? '1' : '0')};
  visibility: ${({ color }) => (color ? 'visible' : 'hidden')};
  ${({ color }) =>
    color === 'red' &&
    css`
      color: ${({ theme }) => theme.colors.red100};
    `}
  ${({ color }) =>
    color === 'green' &&
    css`
      color: ${({ theme }) => theme.colors.green};
      &:before {
        display: none;
      }
    `}
`;

const Heading = styled.span`
  font-size: 1.6rem;
  font-weight: ${({ theme }) => theme.light};
  padding-top: 5px;
  ${({ theme }) => theme.mq.xs} {
    padding-left: 10px;
  }
`;

const RoomCheckboxesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const getYLdata = (name, isClassName = true) => {
  if (isClassName) {
    switch (name.toLowerCase()) {
      case 'term1':
        return 'yl-oferta';
      case 'term2':
        return 'yl-oferta_handlowa_bezposrednia-telefon';
      case 'term3':
        return 'yl-oferta_handlowa';
      case 'term4':
        return 'yl-oferta_handlowa_elektroniczna-email';
      default:
        return null;
    }
  } else {
    switch (name.toLowerCase()) {
      case 'term1':
        return 'acceptance-729';
      case 'term2':
        return 'acceptance-186';
      case 'term3':
        return 'acceptance-448';
      case 'term4':
        return 'acceptance-903';
      default:
        return null;
    }
  }
};

const ContactForm = ({ modalMessage }) => {
  const [submitClicked, setSubmitClicked] = useState(false);
  const [expandedTerms, setExpandedTerms] = useState([]);
  const [formMessage, setFormMessage] = useState({ text: '', color: '' });
  const [roomsNumber, setRoomsNumber] = useState([]);
  const formikRef = useRef();

  const term1Key = modalMessage ? 'term1Modal' : 'term1';
  const term2Key = modalMessage ? 'term2Modal' : 'term2';
  const term3Key = modalMessage ? 'term3Modal' : 'term3';
  const term4Key = modalMessage ? 'term4Modal' : 'term4';
  const acceptAllKey = modalMessage ? 'acceptAllModal' : 'acceptAll';

  const contactSchema = Yup.object().shape({
    name: Yup.string().required('Proszę o podanie imienia'),
    email: Yup.string()
      .email('Adres email jest nieprawidłowy')
      .required('Proszę o podanie adresu email'),
    phone: Yup.string()
      .min(9, 'Numer telefonu jest nieprawidłowy')
      .matches(
        /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i,
        'Numer telefonu jest nieprawidłowy'
      )
      .required('Proszę podać numer telefonu'),
    [term1Key]: Yup.bool().oneOf([true], 'Proszę o zaznaczenie zgód'),
  });

  useEffect(() => {
    if (formikRef.current) {
      formikRef.current.resetForm();
      formikRef.current.setFieldValue('message', modalMessage);
    }
  }, [modalMessage]);

  const {
    wpgraphql: {
      page: { PageHome },
    },
  } = useStaticQuery(graphql`
    query queryForm {
      wpgraphql {
        page(id: "/strona-glowna/", idType: URI) {
          PageHome {
            contactRodo {
              contactRodoContent
              contactRodoName
              contactRodoRequired
            }
          }
        }
      }
    }
  `);

  const terms = PageHome.contactRodo.map(
    ({ contactRodoName, contactRodoRequired, contactRodoContent }) => ({
      name: modalMessage ? `${contactRodoName}Modal` : contactRodoName,
      content: contactRodoContent,
      required: contactRodoRequired,
    })
  );

  const formTerms = terms.reduce((obj, item) => {
    obj[item.name] = false;
    return obj;
  }, {});

  const handleCheckbox = name => {
    if (expandedTerms.includes(name)) {
      setExpandedTerms(expandedTerms.filter(term => term !== name));
    } else setExpandedTerms([...expandedTerms, name]);
  };

  const handleRoomsNumber = roomNumber => {
    if (roomsNumber.includes(roomNumber)) {
      setRoomsNumber(roomsNumber.filter(term => term !== roomNumber));
    } else setRoomsNumber([...roomsNumber, roomNumber]);
  };

  const roomOptions = ['1', '2', '3', '4', 'inne'];

  const getCheckboxText = name => {
    if (modalMessage) return `${name}Modal`;
    return name;
  };

  return (
    <Formik
      innerRef={formikRef}
      initialValues={{
        name: '',
        email: '',
        phone: '',
        message: modalMessage,
        [acceptAllKey]: false,
        ...formTerms,
      }}
      validationSchema={contactSchema}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        const sendEmail = async () => {
          const {
            name,
            email,
            phone,
            message,
            [term1Key]: agree1,
            [term2Key]: agree2,
            [term3Key]: agree3,
            [term4Key]: agree4,
          } = values;

          let params = {
            name,
            agree1,
            agree2,
            agree3,
            agree4,
            url: window.location.href,
          };
          email ? (params = { ...params, email }) : params;
          phone ? (params = { ...params, phone }) : params;
          message ? (params = { ...params, message }) : params;
          roomsNumber.length !== 0
            ? (params = { ...params, rooms: roomsNumber.sort().join() })
            : params;
          if (terms.some(term => term.required && values[term.name] === false))
            return setSubmitting(false);
          try {
            setSubmitting(true);
            const { data } = await axios.get(
              `https://cors-anywhere.indigital.guru/https://matexi.indigital.guru/zeromskiego/inc/mailer.php`,
              {
                params: {
                  ...params,
                  source: 'Strona główna',
                },
              }
            );

            if (window.Monitor) window.Monitor.TrackForm('contactForm', 3, 3);

            const CRMFormParams = {
              ...getFormParams(params),
              leadID: data.leadid,
              visitorID: getCookie('m_vis_id'),
              form_name: 'lp-form-kontakt',
              id_investment: '18',
            };
            // console.log(CRMFormParams);
            const { data: CRMData } = await axios.post(
              `https://api.matexipolska.pl/export/api_makeform/?mode=json`,
              {
                ...CRMFormParams,
              }
            );
            console.log(CRMData);
            window.dataLayer.push({
              event: 'formularz',
              mieszkanie: '',
            });
            window._mtm.push({
              event: 'formularz',
              mieszkanie: '',
            });
            setSubmitting(false);
            setFormMessage({
              text: data.message,
              color: 'green',
            });

            setTimeout(() => {
              setFormMessage({ text: '', color: '' });
              resetForm();
              setRoomsNumber([]);
            }, 10000);
          } catch (err) {
            setSubmitting(false);
            setFormMessage({
              text: 'Coś poszło nie tak!',
              color: 'red',
            });

            setTimeout(() => {
              setFormMessage({ text: '', color: '' });
            }, 2500);
          }
        };
        sendEmail();
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setErrors,
        setFieldValue,
        isSubmitting,
      }) => (
        <StyledForm onSubmit={handleSubmit} id="contactForm">
          <Input
            id="name"
            className="yl-name"
            placeholder="Imię*"
            onChange={handleChange}
            onBlur={handleBlur}
            error={submitClicked && errors.name && touched.name}
            value={values.name}
            touched={touched.name}
            errors={errors.name}
          />
          <Input
            id="email"
            placeholder="Adres email*"
            className="yl-email"
            onChange={handleChange}
            onBlur={handleBlur}
            error={submitClicked && errors.email && touched.email}
            value={values.email}
            touched={touched.email}
            errors={errors.email}
          />
          <Input
            id="phone"
            className="yl-phone"
            placeholder="Telefon*"
            onChange={handleChange}
            onBlur={handleBlur}
            error={submitClicked && errors.phone && touched.phone}
            value={values.phone}
            touched={touched.phone}
            errors={errors.phone}
          />
          <Heading>Liczba pokoi</Heading>
          <RoomCheckboxesWrapper>
            {roomOptions.map((room, index) => (
              <RoomLabel key={room} htmlFor={getCheckboxText(room)}>
                <RoomText>{room}</RoomText>
                <Checkbox
                  id={getCheckboxText(room)}
                  className={`yl-checkbox-pokoje_${index}`}
                  name={`yl-checkbox-pokoje_${index}`}
                  type="checkbox"
                  value={room}
                  onChange={() => handleRoomsNumber(room)}
                  checked={roomsNumber.includes(room)}
                />
                <Checkmark />
              </RoomLabel>
            ))}
          </RoomCheckboxesWrapper>
          <Textarea
            as="textarea"
            id="message"
            className="yl-message"
            placeholder="Wiadomość"
            onChange={handleChange}
            onBlur={handleBlur}
            error={submitClicked && errors.message && touched.message}
            value={values.message}
            touched={touched.message}
            errors={errors.message}
          />
          {terms.length !== 0 && (
            <Label htmlFor={acceptAllKey}>
              Zaznacz wszystkie zgody
              <Checkbox
                id={acceptAllKey}
                name={acceptAllKey}
                type="checkbox"
                onChange={() => {
                  if (!terms.every(term => values[term.name] === true)) {
                    setFieldValue(acceptAllKey, true);
                    terms.forEach(term => setFieldValue(term.name, true));
                  } else {
                    setFieldValue(acceptAllKey, true);
                    terms.forEach(term => setFieldValue(term.name, false));
                  }
                }}
                checked={terms.every(term => values[term.name] === true)}
              />
              <Checkmark />
            </Label>
          )}
          {terms.map(({ name, content, required }) => (
            <Label htmlFor={name} key={content}>
              <LabelText expanded={expandedTerms.includes(name)}>
                {content}
              </LabelText>
              <LabelBtn type="button" onClick={() => handleCheckbox(name)}>
                {expandedTerms.includes(name) ? 'Mniej' : 'Więcej'}
              </LabelBtn>
              <Checkbox
                id={name}
                className={getYLdata(name)}
                name={getYLdata(name, false)}
                type="checkbox"
                onChange={() => {
                  setFieldValue(name, !values[name]);
                  required && setErrors({ ...errors, [term1Key]: '' });
                }}
                checked={values[name]}
              />
              <Checkmark
                error={
                  submitClicked &&
                  errors[term1Key] &&
                  required &&
                  values[name] === false
                }
              />
            </Label>
          ))}
          <FormFooter>
            <ErrorsWrapper>
              {submitClicked && errors.name && touched.name && errors.name && (
                <ErrorMessage>{errors.name}</ErrorMessage>
              )}
              {submitClicked &&
                errors.email &&
                touched.email &&
                errors.email && <ErrorMessage>{errors.email}</ErrorMessage>}
              {submitClicked &&
                errors.phone &&
                touched.phone &&
                errors.phone && <ErrorMessage>{errors.phone}</ErrorMessage>}
              {submitClicked &&
                errors.message &&
                touched.message &&
                errors.message && <ErrorMessage>{errors.message}</ErrorMessage>}
              {submitClicked && errors[term1Key] && (
                <ErrorMessage>{errors[term1Key]}</ErrorMessage>
              )}
              {formMessage.text && (
                <MessageContent color={formMessage.color}>
                  {formMessage.text}
                </MessageContent>
              )}
            </ErrorsWrapper>
            <Button
              onClick={() => setSubmitClicked(true)}
              submit
              disabled={isSubmitting}
              type="submit"
            >
              {isSubmitting ? '' : 'Wyślij'}
            </Button>
          </FormFooter>
        </StyledForm>
      )}
    </Formik>
  );
};

ContactForm.propTypes = {
  modalMessage: PropTypes.string,
};

ContactForm.defaultProps = {
  modalMessage: '',
};

export default ContactForm;
