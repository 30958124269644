/* eslint-disable no-param-reassign */
/* eslint-disable consistent-return */
/* eslint-disable consistent-return */
import React, { useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled, { css, keyframes } from 'styled-components';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import checkIcon from 'assets/icons/confirm.svg';
import errorIcon from 'assets/icons/close-red.svg';
import arrowDownIcon from 'assets/icons/arrow-down.svg';
import Icon from 'components/shared/Icon';
import getFormParams from 'browser/utils/getFormParams';
import getCookie from 'browser/utils/getCookie';

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const StyledForm = styled(Form)`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: visible;
  color: #fff;
  padding: 25px 0;
  background-color: #42baec;
  padding: 0 10%;
`;

const FormHeading = styled.h2`
  width: 100%;
  margin: 40px 0;
  color: #fff;
  font-size: ${({ theme }) => theme.fontSize.m};
  text-transform: uppercase;
  text-align: center;
  ${({ theme }) => theme.mq.s} {
    font-size: ${({ theme }) => theme.fontSize.md};
  }
  ${({ theme }) => theme.mq.md} {
    font-size: ${({ theme }) => theme.fontSize.lg};
  }
  ${({ theme }) => theme.mq.xxl} {
    font-size: ${({ theme }) => theme.fontSize.xl};
  }
`;

const InputsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${({ theme }) => theme.mq.lg} {
    flex-direction: row;
    justify-content: space-between;
  }
`;

const Input = styled.input`
  width: 100%;
  padding: 10px 20px;
  margin-bottom: 30px;
  color: ${({ theme, error }) =>
    error ? theme.colors.red100 : theme.colors.dark};
  font-size: 1.6rem;
  font-family: ${({ theme }) => theme.fonts.mainFont};
  overflow: visible;
  transition: 0.3s;
  border: 1px solid ${({ theme }) => theme.colors.gray100};
  &:focus {
    color: ${({ theme }) => theme.colors.dark} !important;
    border-color: ${({ theme }) => theme.colors.green} !important;
  }
  &::placeholder {
    opacity: 0.8;
    color: ${({ theme }) => theme.colors.dark};
    text-align: left;
  }
  ${({ error }) =>
    error &&
    css`
      border-color: ${({ theme }) => theme.colors.red100};
    `}
  ${({ theme }) => theme.mq.lg} {
    width: 23.5%;
    padding: 12px 20px;
  }
`;

const Label = styled.label`
  display: flex;
  align-items: center;
  min-height: 25px;
  position: relative;
  padding-left: 40px;
  margin: 15px 0;
  cursor: pointer;
  font-weight: ${({ theme }) => theme.light};
  font-size: ${({ theme }) => theme.fontSize.xs};
  user-select: none;
  line-height: 18px;
  ${({ theme }) => theme.mq.xs} {
    padding-left: 50px;
    font-size: ${({ theme }) => theme.fontSize.s};
  }
  b {
    color: #fff;
    margin-left: -10px;
    margin-bottom: 2px;
  }
`;

const RoomLabel = styled(Label)`
  padding-left: 28px !important;
  font-size: 1.7rem !important;
  margin: 10px 20px 15px 0;
  ${({ theme }) => theme.mq.xs} {
    margin: 15px 20px 20px 10px;
    padding-left: 35px !important;
  }
`;

const RoomText = styled.p`
  position: absolute;
  top: 1px;
`;

const LabelBtn = styled.button`
  background: transparent;
  cursor: pointer;
  align-self: flex-end;
  transition: 0.1s;
  ${({ inverted }) =>
    inverted &&
    css`
      transform: rotate(180deg);
    `}
  ${({ theme }) => theme.mq.lg} {
    margin-top: 10px;
    margin-right: -5px;
  }
`;

const LabelText = styled.p`
  font-size: 1.2rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  font-weight: ${({ theme }) => theme.light};
  ${({ expanded }) =>
    expanded &&
    css`
      display: block;
      text-overflow: clip;
      -webkit-line-clamp: none;
    `}
`;

const Checkmark = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background: ${({ theme }) => theme.colors.white};
  border: 1px solid;
  border-color: ${({ theme, error }) =>
    error ? theme.colors.red100 : theme.colors.gray100};
  &:after {
    content: '';
    position: absolute;
    display: none;
    left: 3px;
    top: 3px;
    width: 12px;
    height: 12px;
    background: url(${checkIcon}) no-repeat center;
  }
`;

const Checkbox = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
  &:checked ~ ${Checkmark}:after {
    display: block;
  }
`;

const FormFooter = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px 0;
  font-weight: ${({ theme }) => theme.light};
  & > button {
    margin: 0 auto;
  }
  ${({ theme }) => theme.mq.lg} {
    flex-direction: row;
    justify-content: space-between;
    margin: 40px 0 20px;
  }
`;

const ErrorsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  & > span {
    margin-bottom: 8px;
    &:last-child {
      margin-bottom: 25px;
    }
  }
`;

const ErrorMessage = styled.span`
  display: flex;
  font-size: ${({ theme }) => theme.fontSize.m};
  color: ${({ theme }) => theme.colors.red100};
  line-height: 18px;
  &:before {
    content: '';
    margin-top: 1px;
    margin-right: 10px;
    width: 12px;
    height: 12px;
    background: url(${errorIcon}) no-repeat center;
    background-size: 12px 12px;
  }
`;

const MessageContent = styled(ErrorMessage)`
  opacity: ${({ color }) => (color ? '1' : '0')};
  visibility: ${({ color }) => (color ? 'visible' : 'hidden')};
  ${({ color }) =>
    color === 'red' &&
    css`
      color: ${({ theme }) => theme.colors.red100};
    `}
  ${({ color }) =>
    color === '#FDDB27FF' &&
    css`
      color: #fddb27ff;
      &:before {
        display: none;
      }
    `}
`;

const Heading = styled.span`
  text-align: center;
  font-size: ${({ theme }) => theme.fontSize.m};
  font-weight: ${({ theme }) => theme.light};
  padding-top: 5px;
`;

const RoomCheckboxesWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`;

const TermsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px 0 25px;
  border-top: 1px solid white;
  border-bottom: 1px solid white;
`;

const SingleTermsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  ${({ theme }) => theme.mq.lg} {
    flex-direction: row;
    justify-content: space-between;
    & > label {
      width: 23.5%;
      flex-direction: column;
      padding-left: 32px;
    }
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: stretch;
  width: 70%;
  height: 42px;
  min-width: 220px;
  max-width: 540px;
  margin: 0 auto;
  position: relative;
  ${({ theme }) => theme.mq.s} {
    height: 50px;
  }
  ${({ theme }) => theme.mq.lg} {
    width: 35%;
  }
`;

const Button = styled.button`
  position: relative;
  display: block;
  padding: 12px 28px;
  background-color: #ea5a0b;
  color: ${({ theme }) => theme.colors.lightYellow};
  font-family: ${({ theme }) => theme.fonts.subFont};
  font-weight: ${({ theme }) => theme.bold};
  font-size: 1.4rem;
  width: 100%;
  margin: 0;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  text-transform: uppercase;
  text-decoration: none;
  transition: 0.3s;
  text-align: center;
  letter-spacing: 1px;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    border-top: 21px solid transparent;
    border-bottom: 21px solid transparent;
    border-left: 29px solid #fff;
  }
  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 0;
    height: 0;
    border-top: 21px solid transparent;
    border-bottom: 21px solid transparent;
    border-right: 29px solid #fff;
  }

  ${({ theme }) => theme.mq.s} {
    &::before {
      border-top: 25px solid transparent;
      border-bottom: 25px solid transparent;
      border-left: 50px solid #fff;
    }

    &::after {
      border-top: 25px solid transparent;
      border-bottom: 25px solid transparent;
      border-right: 50px solid #fff;
    }
  }
`;

const ButtonLoader = styled.div`
  position: absolute;
  top: calc(50% - 12px);
  left: calc(50% - 12px);
  width: 24px;
  height: 24px;
  border: 3px solid ${({ theme }) => theme.white};
  border-top: 3px solid transparent;
  border-bottom: 3px solid transparent;
  border-radius: 50%;
  background: none;
  opacity: ${({ isSubmitting }) => (isSubmitting ? '1' : '0')};
  animation: ${spin} 1s ease infinite;
  z-index: 5;
`;

const getYLdata = (name, isClassName = true) => {
  if (isClassName) {
    switch (name.toLowerCase()) {
      case 'term1':
        return 'yl-oferta';
      case 'term2':
        return 'yl-oferta_handlowa_bezposrednia-telefon';
      case 'term3':
        return 'yl-oferta_handlowa';
      case 'term4':
        return 'yl-oferta_handlowa_elektroniczna-email';
      default:
        return null;
    }
  } else {
    switch (name.toLowerCase()) {
      case 'term1':
        return 'acceptance-729';
      case 'term2':
        return 'acceptance-186';
      case 'term3':
        return 'acceptance-448';
      case 'term4':
        return 'acceptance-903';
      default:
        return null;
    }
  }
};

const AskForm = () => {
  const [submitClicked, setSubmitClicked] = useState(false);
  const [expandedTerms, setExpandedTerms] = useState([]);
  const [formMessage, setFormMessage] = useState({ text: '', color: '' });
  const [roomsNumber, setRoomsNumber] = useState([]);

  const term1Key = 'term1';
  const term2Key = 'term2';
  const term3Key = 'term3';
  const term4Key = 'term4';
  const acceptAllKey = 'acceptAll';

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Proszę o podanie imienia'),
    surname: Yup.string(),
    email: Yup.string()
      .email('Adres email jest nieprawidłowy')
      .required('Proszę o podanie adresu email'),
    phone: Yup.string()
      .min(9, 'Numer telefonu jest nieprawidłowy')
      .matches(
        /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i,
        'Numer telefonu jest nieprawidłowy'
      )
      .required('Proszę podać numer telefonu'),
    [term1Key]: Yup.bool().oneOf([true], 'Proszę o zaznaczenie zgód'),
  });

  const {
    wpgraphql: {
      page: { PageHome },
    },
  } = useStaticQuery(graphql`
    query queryFormTerms {
      wpgraphql {
        page(id: "/strona-glowna/", idType: URI) {
          PageHome {
            contactRodo {
              contactRodoContent
              contactRodoName
              contactRodoRequired
            }
          }
        }
      }
    }
  `);

  const terms = PageHome.contactRodo.map(
    ({ contactRodoName, contactRodoRequired, contactRodoContent }) => ({
      name: contactRodoName,
      content: contactRodoContent,
      required: contactRodoRequired,
    })
  );

  const formTerms = terms.reduce((obj, item) => {
    obj[item.name] = false;
    return obj;
  }, {});

  const handleCheckbox = name => {
    if (expandedTerms.includes(name)) {
      setExpandedTerms(expandedTerms.filter(term => term !== name));
    } else setExpandedTerms([...expandedTerms, name]);
  };

  const handleRoomsNumber = roomNumber => {
    if (roomsNumber.includes(roomNumber)) {
      setRoomsNumber(roomsNumber.filter(term => term !== roomNumber));
    } else setRoomsNumber([...roomsNumber, roomNumber]);
  };

  const roomOptions = ['1', '2', '3', '4', 'inne'];

  return (
    <Formik
      initialValues={{
        name: '',
        surname: '',
        email: '',
        phone: '',
        [acceptAllKey]: false,
        ...formTerms,
      }}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        const sendEmail = async () => {
          const {
            name,
            surname,
            email,
            phone,
            [term1Key]: agree1,
            [term2Key]: agree2,
            [term3Key]: agree3,
            [term4Key]: agree4,
          } = values;

          let params = {
            name,
            surname,
            agree1,
            agree2,
            agree3,
            agree4,
            url: window.location.href,
          };
          params = email ? { ...params, email } : params;
          params = phone ? { ...params, phone } : params;
          roomsNumber.length !== 0
            ? (params = { ...params, rooms: roomsNumber.sort().join() })
            : params;
          if (terms.some(term => term.required && values[term.name] === false))
            return setSubmitting(false);

          try {
            setSubmitting(true);
            const query = `
              mutation sendContactFormTop {
                sendAskApartmentForm(
                  input: {
                    agree2: ${agree2}
                    agree3: ${agree3}
                    agree1: ${agree1}
                    agree4: ${agree4}
                    email: "${email}"
                    name: "${name}"
                    surname: "${surname}"
                    phone: "${phone}"
                    rooms: "${params.rooms}"
                    clientMutationId: ""
                  }
                ) {
                  clientMutationId
                  code
                  message
                  status
                }
              }
            `;
            const { data } = await axios.post(
              `https://matexi.indigital.guru/zeromskiego/graphql`,
              {
                query,
              }
            );

            if (window.Monitor)
              window.Monitor.TrackForm('contactFormTop', 3, 3);

            const CRMFormParams = {
              ...getFormParams(params),
              leadID: data.leadid,
              visitorID: getCookie('m_vis_id'),
              form_name: 'lp-form-kontakt-top',
              id_investment: '18',
            };
            const { data: CRMData } = await axios.post(
              `https://api.matexipolska.pl/export/api_makeform/?mode=json`,
              {
                ...CRMFormParams,
              }
            );

            if (window.dataLayer) {
              window.dataLayer.push({
                event: 'formularz',
                mieszkanie: '',
              });
              window._mtm.push({
                event: 'formularz',
                mieszkanie: '',
              });
            }
            setSubmitting(false);
            setFormMessage({
              text: data.data.sendAskApartmentForm.message,
              color: '#FDDB27FF',
            });

            setTimeout(() => {
              setFormMessage({ text: '', color: '' });
              resetForm();
              setRoomsNumber([]);
            }, 10000);
          } catch (err) {
            setSubmitting(false);
            setFormMessage({
              text: 'Coś poszło nie tak!',
              color: 'red',
            });

            setTimeout(() => {
              setFormMessage({ text: '', color: '' });
            }, 2500);
          }
        };
        sendEmail();
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setErrors,
        setFieldValue,
        isSubmitting,
      }) => (
        <StyledForm onSubmit={handleSubmit} id="contactFormTop">
          <FormHeading>zapytaj o mieszkanie</FormHeading>
          <InputsWrapper>
            <Input
              id="name"
              placeholder="Imię*"
              onChange={handleChange}
              onBlur={handleBlur}
              error={submitClicked && errors.name && touched.name}
              value={values.name}
              touched={touched.name}
              errors={errors.name}
            />
            <Input
              id="surname"
              placeholder="Nazwisko"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.surname}
              touched={touched.surname}
            />
            <Input
              id="email"
              placeholder="Adres email*"
              onChange={handleChange}
              onBlur={handleBlur}
              error={submitClicked && errors.email && touched.email}
              value={values.email}
              touched={touched.email}
              errors={errors.email}
            />
            <Input
              id="phone"
              placeholder="Telefon*"
              onChange={handleChange}
              onBlur={handleBlur}
              error={submitClicked && errors.phone && touched.phone}
              value={values.phone}
              touched={touched.phone}
              errors={errors.phone}
            />
          </InputsWrapper>
          <Heading>Ilość pokoi</Heading>
          <RoomCheckboxesWrapper>
            {roomOptions.map((room, index) => (
              <RoomLabel key={room} htmlFor={`askForm-${room}`}>
                <RoomText>{room}</RoomText>
                <Checkbox
                  id={`askForm-${room}`}
                  name={`yl-checkbox-pokoje_${index}`}
                  type="checkbox"
                  value={room}
                  onChange={() => handleRoomsNumber(room)}
                  checked={roomsNumber.includes(room)}
                />
                <Checkmark />
              </RoomLabel>
            ))}
          </RoomCheckboxesWrapper>
          <TermsWrapper>
            {terms.length !== 0 && (
              <Label htmlFor={`askForm-${acceptAllKey}`}>
                <b>Zaznacz wszystkie zgody</b>
                <Checkbox
                  id={`askForm-${acceptAllKey}`}
                  name={`askForm-${acceptAllKey}`}
                  type="checkbox"
                  onChange={() => {
                    if (!terms.every(term => values[term.name] === true)) {
                      setFieldValue(acceptAllKey, true);
                      terms.forEach(term => setFieldValue(term.name, true));
                    } else {
                      setFieldValue(acceptAllKey, true);
                      terms.forEach(term => setFieldValue(term.name, false));
                    }
                  }}
                  checked={terms.every(term => values[term.name] === true)}
                />
                <Checkmark />
              </Label>
            )}
            <SingleTermsWrapper>
              {terms.map(({ name, content, required }) => (
                <Label htmlFor={`askForm-${name}`} key={content}>
                  <LabelText expanded={expandedTerms.includes(name)}>
                    {content}
                  </LabelText>
                  <LabelBtn
                    type="button"
                    onClick={() => handleCheckbox(name)}
                    inverted={expandedTerms.includes(name)}
                  >
                    <Icon src={arrowDownIcon} size={12} />
                  </LabelBtn>
                  <Checkbox
                    id={`askForm-${name}`}
                    name={getYLdata(name, false)}
                    type="checkbox"
                    onChange={() => {
                      setFieldValue(name, !values[name]);
                      required && setErrors({ ...errors, [term1Key]: '' });
                    }}
                    checked={values[name]}
                  />
                  <Checkmark
                    error={
                      submitClicked &&
                      errors[term1Key] &&
                      required &&
                      values[name] === false
                    }
                  />
                </Label>
              ))}
            </SingleTermsWrapper>
          </TermsWrapper>
          <FormFooter>
            <ErrorsWrapper>
              {submitClicked && errors.name && touched.name && errors.name && (
                <ErrorMessage>{errors.name}</ErrorMessage>
              )}
              {submitClicked &&
                errors.email &&
                touched.email &&
                errors.email && <ErrorMessage>{errors.email}</ErrorMessage>}
              {submitClicked &&
                errors.phone &&
                touched.phone &&
                errors.phone && <ErrorMessage>{errors.phone}</ErrorMessage>}
              {submitClicked && errors[term1Key] && (
                <ErrorMessage>{errors[term1Key]}</ErrorMessage>
              )}
              {formMessage.text && (
                <MessageContent color={formMessage.color}>
                  {formMessage.text}
                </MessageContent>
              )}
            </ErrorsWrapper>
            <ButtonWrapper>
              <Button
                onClick={() => setSubmitClicked(true)}
                disabled={isSubmitting}
                type="submit"
              >
                {isSubmitting ? '' : 'wyślij zapytanie'}
              </Button>
              <ButtonLoader isSubmitting={isSubmitting} />
            </ButtonWrapper>
          </FormFooter>
        </StyledForm>
      )}
    </Formik>
  );
};

export default AskForm;
