import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import logoIcon from 'assets/icons/logo-matexi.svg';

const Wrapper = styled.a`
  display: block;
  width: 160px;
  height: ${({ theme }) => theme.navSize.mobile};
  cursor: pointer;
  padding: 20px 0;
  ${({ theme }) => theme.mq.lg} {
    padding: 10px 0;
    width: 220px;
    height: ${({ theme }) => theme.navSize.desktop};
  }
  ${({ big }) =>
    big &&
    css`
      width: 180px;
      height: 90px;
      margin-bottom: 10px;
    `}
`;

const Image = styled.img`
  display: block;
  width: 100%;
  height: 100%;
`;

const Logo = ({ big }) => (
  <Wrapper big={big} href="https://www.matexipolska.pl" target="_blank">
    <Image src={logoIcon} alt="logo" />
  </Wrapper>
);

Logo.propTypes = {
  big: PropTypes.bool,
};

Logo.defaultProps = {
  big: false,
};

export default Logo;
