import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Section from 'components/shared/Section';
import Content from 'components/shared/Content';
import Icon from 'components/shared/Icon';
import notusLogo from 'assets/images/notus.jpg';
import creditKV from 'assets/icons/kvCredit.png';

const StyledSection = styled(Section)`
  position: relative;
  padding-top: 0 !important;
`;

const Wrapper = styled.div`
  max-width: 1080px;
  margin: 0 auto;
`;

const Heading = styled.h2`
  font-size: 2.3rem;
  line-height: normal;
  font-weight: ${({ theme }) => theme.light};
  color: ${({ theme }) => theme.colors.green};
  margin-bottom: 15px;
  ${({ theme }) => theme.mq.s} {
    font-size: 2.6rem;
  }
`;

const Description = styled.p`
  font-weight: ${({ theme }) => theme.light};
  padding: 20px 0 15px;
  line-height: 2.2rem;
  text-align: justify;
`;

const Detail = styled(Description)`
  padding: 0;
  text-align: left;
`;

const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const FlexWrapper = styled.div`
  display: flex;
  flex-direction: column;
  ${({ theme }) => theme.mq.md} {
    flex-direction: row;
    margin-top: 20px;
  }
`;

const TeamMember = styled.div`
  display: flex;
  justify-content: flex-start;
  margin: 10px 0;
`;

const TeamIcon = styled.div`
  display: none;
  margin: 0 20px 0 0;
  ${({ theme }) => theme.mq.xs} {
    display: block;
  }
`;

const Flex = styled.div`
  display: flex;
  flex-wrap: wrap;
  & > a {
    text-decoration: none;
    color: ${({ theme }) => theme.colors.gray};
    margin-right: 10px;
    span {
      color: ${({ theme }) => theme.colors.green};
    }
  }
`;

const ImageWrapper = styled.div`
  width: 100%;
  max-width: 240px;
  height: 90px;
  margin-bottom: 15px;
  ${({ theme }) => theme.mq.xs} {
    margin-top: 20px;
  }
  ${({ theme }) => theme.mq.md} {
    margin-bottom: 0;
    margin-right: 15px;
  }
  ${({ theme }) => theme.mq.lg} {
    margin-right: 45px;
  }
`;

const Spacer = styled.div`
  margin-bottom: 80px;
  position: relative;
  width: 100%;
  height: 40px;
  background: ${({ theme }) => theme.colors.blue};
`;

const IconWrapper = styled.figure`
  display: none;
  ${({ theme }) => theme.mq.xl} {
    display: block;
    position: absolute;
    width: 220px;
    bottom: 0;
    right: 0;
    transform: rotate(180deg);
  }
`;

const Credit = ({ heading, description, teamHeading, team }) => {
  return (
    <StyledSection id="credit">
      <Spacer />
      <IconWrapper aria-hidden>
        <Icon src={creditKV} alt="key visual" full />
      </IconWrapper>
      <Content>
        <Wrapper>
          <Heading>{heading}</Heading>
          <Description dangerouslySetInnerHTML={{ __html: description }} />
          <Description dangerouslySetInnerHTML={{ __html: teamHeading }} />
          <FlexWrapper>
            <ImageWrapper>
              <Icon src={notusLogo} alt="notus logo" full />
            </ImageWrapper>
            <InnerWrapper>
              {team.map(({ icon, name, job, phone, email }) => (
                <TeamMember key={name}>
                  <TeamIcon>
                    <Icon size={44} src={icon} alt="person icon" />
                  </TeamIcon>
                  <InnerWrapper>
                    <Detail>
                      <b>{name}</b> - {job}
                    </Detail>
                    <Flex>
                      <Detail as="a" href={`tel:${phone}`}>
                        Tel.: {phone}
                      </Detail>
                      <Detail as="a" href={`mailto:${email}`}>
                        email: <span>{email}</span>
                      </Detail>
                    </Flex>
                  </InnerWrapper>
                </TeamMember>
              ))}
            </InnerWrapper>
          </FlexWrapper>
        </Wrapper>
      </Content>
    </StyledSection>
  );
};

Credit.propTypes = {
  heading: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  teamHeading: PropTypes.string.isRequired,
  team: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Credit;
