import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Section from 'components/shared/Section';
import Button from 'components/shared/Button';
import LazyImage from 'components/shared/LazyImage';
import LazyLoad from 'react-lazyload';
import Browser from 'components/Flats/BrowserWrapper';
import scrollToSection from 'utils/scrollToSection';

const StyledSection = styled(Section)`
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  #search-3d-anchor {
    display: block;
    height: 0;
  }
`;

const Wrapper = styled.div`
  display: none;
  position: relative;
  width: 100%;
  background: ${({ theme }) => theme.colors.lightGreen100};
  ${({ theme }) => theme.mq.lg} {
    display: block;
  }
`;

const ImageWrapper = styled.div`
  display: block;
`;

const Heading = styled.h2`
  font-size: 3rem;
  line-height: 3.6rem;
  font-weight: ${({ theme }) => theme.bold};
`;

const Description = styled.p`
  font-weight: ${({ theme }) => theme.bold};
  padding: 20px 0 25px;
  font-size: 2.1rem;
  line-height: 2.4rem;
`;

const Column = styled.div`
  padding: 40px 0;
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  max-width: 600px;
  color: ${({ theme }) => theme.colors.white};
  button {
    max-width: max-content;
  }
  ${({ theme }) => theme.mq.lg} {
    position: absolute;
    top: 35%;
    left: 20%;
    transform: translate(-20%, -35%);
  }
  ${({ theme }) => theme.mq.xl} {
    left: 30%;
    transform: translate(-30%, -35%);
  }
  @media (min-width: 1500px) {
    left: 40%;
    transform: translate(-40%, -35%);
  }
`;

const Flats = ({ image, imageAlt, heading, description }) => {
  return (
    <StyledSection id="flats">
      <Wrapper>
        <ImageWrapper>
          <LazyImage minHeight="450px" src={image} alt={imageAlt} cover />
        </ImageWrapper>
        <Column>
          <Heading dangerouslySetInnerHTML={{ __html: heading }} />
          <Description dangerouslySetInnerHTML={{ __html: description }} />
          <Button
            secondary
            onClick={() => scrollToSection('#search-3d-anchor')}
          >
            Zobacz mieszkania
          </Button>
        </Column>
      </Wrapper>
      <span id="search-3d-anchor" />
      <LazyLoad offset={500} height="100vh" once>
        <Browser />
      </LazyLoad>
    </StyledSection>
  );
};

Flats.propTypes = {
  image: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ).isRequired,
  imageAlt: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export default Flats;
