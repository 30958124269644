/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Section from 'components/shared/Section';
import Content from 'components/shared/Content';
import Icon from 'components/shared/Icon';
import ContactForm from 'components/Contact/ContactForm';
import ContactMap from 'components/Contact/ContactMap';
import LazyLoad from 'react-lazyload';
import phoneIcon from 'assets/icons/phone.svg';
import emailIcon from 'assets/icons/email.svg';

const StyledSection = styled(Section)`
  padding-bottom: 40px !important;
`;

const SectionInnerWrapper = styled.div`
  padding: 20px 0 70px;
  background: ${({ theme }) => theme.colors.lightBlue};
`;

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 30px;
  ${({ theme }) => theme.mq.lg} {
    grid-template-columns: repeat(2, 1fr);
    justify-content: space-between;
  }
`;

const ContactAddress = styled.address`
  position: relative;
  display: flex;
  flex-direction: column;
  font-weight: ${({ theme }) => theme.light};
  background: ${({ theme }) => theme.colors.green};
  color: ${({ theme }) => theme.colors.white};
  ${({ theme }) => theme.mq.lg} {
    max-width: 700px;
  }
`;

const Padding = styled.div`
  padding: 30px 15px 10px;
`;

const Title = styled.h2`
  font-size: 2.2rem;
  line-height: 2.8rem;
  text-align: center;
  font-weight: 700;
  ${({ theme }) => theme.mq.xs} {
    font-size: 2.6rem;
    line-height: 3.4rem;
  }
`;

const Subtitle = styled.h3`
  font-size: 1.8rem;
  line-height: 2.6rem;
  text-align: center;
  ${({ theme }) => theme.mq.xs} {
    font-size: 2.2rem;
    line-height: 3rem;
  }
  & + p {
    text-align: center;
    max-width: 600px;
    font-weight: 400;
    a {
      font-weight: 700;
    }
  }
`;

const MapWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 250px;
  ${({ theme }) => theme.mq.s} {
    height: 400px;
  }
`;

const Heading = styled.h3`
  font-size: 2.4rem;
  line-height: 2.6rem;
  text-transform: uppercase;
  font-weight: ${({ theme }) => theme.light};
  padding: 10px 0;
  color: ${({ theme }) => theme.colors.green};
`;

const Column = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  max-width: 250px;
  margin: 0 auto;
  text-align: center;
  gap: 10px;
  & > * {
    font-size: 1.9rem;
    font-weight: ${({ theme }) => theme.light};
  }
`;

const Detail = styled.p`
  font-size: 1.9rem;
  font-weight: ${({ theme }) => theme.light};
  padding-bottom: 15px;
  text-align: center;
`;

const Phone = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.fonts.specialFont};
  text-decoration: none;
  font-size: 4rem;
  span {
    margin-left: 10px;
    transform: rotate(-5deg) translateY(-10px);
  }
  ${({ theme }) => theme.mq.s} {
    font-size: 6.4rem;
  }
  ${({ theme }) => theme.mq.md} {
    font-size: 8rem;
  }
`;

const PhoneIcon = styled.div`
  width: 35px;
  height: 35px;
  ${({ theme }) => theme.mq.s} {
    width: 50px;
    height: 50px;
  }
`;

const EmailWrapper = styled.div`
  position: absolute;
  bottom: -50px;
  left: 50%;
  transform: translateX(-50%);
  padding: 0 10px;
  ${({ theme }) => theme.mq.s} {
    bottom: -60px;
  }
  ${({ theme }) => theme.mq.xl} {
    bottom: -65px;
  }
`;

const Email = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.colors.dark};
  font-family: ${({ theme }) => theme.fonts.specialFont};
  text-decoration: none;
  font-size: 2.6rem;
  span {
    margin-left: 10px;
  }
  ${({ theme }) => theme.mq.s} {
    font-size: 5rem;
  }
  ${({ theme }) => theme.mq.xl} {
    font-size: 5.8rem;
  }
`;

const StyledDetail = styled(Detail)`
  padding: 0 0 10px;
  max-width: 500px;
  margin: 0 auto;
  text-align: left;
  font-weight: 600;
  font-size: 1.6rem;
  line-height: normal;
  p {
    margin: 10px 0;
  }
  a {
    color: #fff;
    text-decoration: none;
  }
  ${({ theme }) => theme.mq.s} {
    font-size: 1.8rem;
  }
`;

const Info = styled(StyledDetail)`
  padding: 20px 0;
`;

const PDFButton = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 2rem;
  font-family: ${({ theme }) => theme.fonts.subFont};
  background: #e21a65;
  transition: 0.3s;
  color: #fff;
  text-decoration: none;
  font-weight: 700;
  text-align: center;
  padding: 12px 40px;
  &:hover {
    background: ${({ theme }) => theme.colors.red100};
  }
  margin-top: 30px;
  ${({ theme }) => theme.mq.lg} {
    margin-top: auto;
  }
`;

const LeftColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const Contact = ({
  mapCords,
  title,
  address,
  phone,
  email,
  openingHours,
  content,
  infos,
  pdf,
}) => (
  <StyledSection>
    <SectionInnerWrapper>
      <Content>
        <Wrapper>
          <LeftColumn>
            <Heading id="contact">Kontakt</Heading>
            <ContactForm />
            {pdf.link && pdf.text && (
              <PDFButton
                href={pdf.link}
                target="_blank"
                dangerouslySetInnerHTML={{
                  __html: pdf.text,
                }}
              />
            )}
          </LeftColumn>
          <ContactAddress>
            <Padding>
              <Title>{title}</Title>
              <Subtitle dangerouslySetInnerHTML={{ __html: address }} />
              <Info dangerouslySetInnerHTML={{ __html: content }} />
            </Padding>
            <LazyLoad height={400} offset={500} once>
              <MapWrapper>
                <ContactMap lat={mapCords.lat} lng={mapCords.lng} />
              </MapWrapper>
            </LazyLoad>
            <Padding>
              <Detail>Godziny otwarcia:</Detail>
              <Column>
                {openingHours.map(({ day, openingTime, closingTime }) => (
                  <React.Fragment key={day}>
                    <span>{day}</span>
                    <time>{openingTime}</time>
                    <span>{closingTime && '-'}</span>
                    <time>{closingTime}</time>
                  </React.Fragment>
                ))}
              </Column>
              {infos.map((info, index) => (
                <Info key={index} dangerouslySetInnerHTML={{ __html: info }} />
              ))}
              <Phone href={`tel:${phone}`}>
                <PhoneIcon>
                  <Icon full src={phoneIcon} alt="phone" />
                </PhoneIcon>
                <span> {phone}</span>
              </Phone>
            </Padding>
            <EmailWrapper>
              <Email href={`mailto:${email}`}>
                <PhoneIcon>
                  <Icon full src={emailIcon} alt="email" />
                </PhoneIcon>
                <span> {email}</span>
              </Email>
            </EmailWrapper>
          </ContactAddress>
        </Wrapper>
      </Content>
    </SectionInnerWrapper>
  </StyledSection>
);

Contact.propTypes = {
  title: PropTypes.string.isRequired,
  address: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  mapCords: PropTypes.objectOf(PropTypes.number).isRequired,
  pdf: PropTypes.objectOf(PropTypes.string).isRequired,
  openingHours: PropTypes.arrayOf(PropTypes.object).isRequired,
  infos: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default Contact;
