/* eslint-disable react/prop-types */
import React, { useRef, useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import modalImg from 'assets/images/zeromskiego-popup-grafika.jpg';
import useOutsideClick from 'hooks/useOutsideClick';
import scrollToSection from 'utils/scrollToSection';
import Icon from 'components/shared/Icon';
import close from 'assets/icons/close2.svg';

const ModalOverlay = styled.aside`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #0000004c;
  z-index: 1000;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s;
  ${({ visible }) =>
    visible &&
    css`
      opacity: 1;
      visibility: visible;
    `}
`;

const ImgWrapper = styled.div`
  position: relative;
  max-width: 95vw;
  ${({ visible }) =>
    visible &&
    css`
      opacity: 1;
      visibility: visible;
    `}
  img {
    max-width: 100%;
  }
`;

const Content = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: min(5vw, 3.75rem);
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  color: ${({ theme }) => theme.colors.white};
  text-transform: uppercase;
  letter-spacing: 2px;
`;

const Wrapper = styled.div`
  width: 100%;
  text-align: left;
`;

const Heading = styled.h2`
  font-size: clamp(1.8rem, 6.5vw, 4.7rem);
  font-weight: ${({ theme }) => theme.medium};
  line-height: 1.1;
`;

const Text = styled.p`
  font-size: clamp(1.2rem, 4.1vw, 3rem);
  line-height: 1.3;
`;

const Button = styled.button`
  margin-top: 1rem;
  padding: 0.8rem min(6vw, 4rem);
  font-size: clamp(1rem, 2.5vw, 2.1rem);
  font-weight: ${({ theme }) => theme.semiBold};
  background-color: #d70b52;
  border: 3px solid ${({ theme }) => theme.colors.white};
  box-shadow: -5px 8px 8px rgb(35 75 120);
  color: ${({ theme }) => theme.colors.white};
  text-transform: uppercase;
  cursor: pointer;
`;

const CloseIconWrapper = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
  background-color: #fff;
  border-radius: 50%;
  cursor: pointer;
  ${({ theme }) => theme.mq.s} {
    top: 15px;
    right: 15px;
  }
`;

const Modal = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsVisible(true);
    }, 3000);
  }, []);

  const modalRef = useRef(null);
  useOutsideClick(modalRef, setIsVisible);

  return (
    <ModalOverlay visible={isVisible}>
      <ImgWrapper ref={modalRef} visible={isVisible}>
        <img src={modalImg} alt="zeromskiego-investment" />
        <Content>
          <Wrapper>
            <Heading>
              szukasz <br /> mieszkania?
            </Heading>
            <Text>
              odbierz klucze <br /> w tym roku
            </Text>
          </Wrapper>
          <Wrapper>
            <Text>i zamieszkaj na żeromskiego.</Text>
            <Button
              onClick={() => {
                scrollToSection('#form-top');
                setIsVisible(false);
              }}
            >
              zapytaj o szczegóły
            </Button>
          </Wrapper>
        </Content>
        <CloseIconWrapper onClick={() => setIsVisible(false)}>
          <Icon src={close} alt="close" size={15} />
        </CloseIconWrapper>
      </ImgWrapper>
    </ModalOverlay>
  );
};

export default Modal;
