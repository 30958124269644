/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Button from 'components/shared/Button';
import Content from 'components/shared/Content';
import LazyImage from 'components/shared/LazyImage';
import Icon from 'components/shared/Icon';
import logoBig from 'assets/icons/logo-big.png';
import kvHeader from 'assets/icons/header-kv.svg';
import useMedia from 'hooks/useMedia';
import scrollToSection from 'utils/scrollToSection';

const Wrapper = styled.header`
  position: relative;
`;

const InnerWrapper = styled.div`
  position: relative;
  background: ${({ theme }) => theme.colors.blue};
  width: 100%;
  & > div {
    padding: 0 !important;
  }
`;

const ImageWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 300px;
  ${({ theme }) => theme.mq.xs} {
    height: 470px;
  }
  ${({ theme }) => theme.mq.xl} {
    height: 600px;
  }
`;

const KVImage = styled.div`
  display: none;
  ${({ theme }) => theme.mq.md} {
    display: block;
    position: absolute;
    bottom: 0;
    z-index: 2;
    width: 600px;
    height: 350px;
    left: -250px;
    pointer-events: none;
    user-select: none;
  }
  ${({ theme }) => theme.mq.xl} {
    width: 800px;
    height: 500px;
    bottom: -250px;
    left: -326px;
  }
`;

const KVIcon = styled.figure`
  display: block;
  position: absolute;
  bottom: 7px;
  left: -290px;
  z-index: 2;
  width: 400px;
  height: 300px;
  transform: rotate(180deg);
  pointer-events: none;
  user-select: none;
  ${({ theme }) => theme.mq.xs} {
    transform: none;
    left: auto;
    right: -358px;
    width: 500px;
    height: 400px;
    bottom: 7px;
  }
`;

const Heading = styled.h1`
  font-size: 3rem;
  color: ${({ theme }) => theme.colors.blue};
  font-weight: 700;
  margin-bottom: 15px;
`;

const Description = styled.p`
  text-align: justify;
  font-size: 1.6rem;
  line-height: 2.8rem;
  em {
    color: ${({ theme }) => theme.colors.blue};
    font-weight: 700;
  }
  sup {
    font-weight: 700;
    color: #000;
  }
  ${({ theme }) => theme.mq.s} {
    text-align: left;
    font-size: 1.8rem;
  }
`;

const LogoWrapper = styled.div`
  position: absolute;
  top: 30px;
  right: 50px;
  min-width: 220px;
  margin: 0 0 30px;
  max-width: 220px;
  ${({ theme }) => theme.mq.xs} {
    max-width: none;
  }
  ${({ theme }) => theme.mq.xl} {
    margin: 30px 0 40px;
  }
`;

const ContentWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 30px 0;
  ${({ theme }) => theme.mq.md} {
    padding: 30px 20px;
  }
  ${({ theme }) => theme.mq.xl} {
    padding: 40px 20px;
    max-width: 900px;
    margin-left: auto;
  }
`;

const ButtonWrapper = styled.div`
  position: absolute;
  right: 20px;
  bottom: 20px;
  z-index: 1;
  ${({ theme }) => theme.mq.xs} {
    width: 320px;
    right: 50px;
    bottom: 50px;
  }
  button {
    width: 100%;
    max-width: none;
    font-size: 1.4rem;
    ${({ theme }) => theme.mq.xs} {
      font-size: 1.6rem;
    }
  }
`;

const Header = ({ image, imageAlt, image2, description }) => {
  const matchesLG = useMedia('(min-width: 992px)');

  return (
    <Wrapper>
      <InnerWrapper>
        <Content>
          <ImageWrapper>
            <KVImage aria-hidden>
              <LazyImage src={image2} alt="key visual" />
            </KVImage>
            <KVIcon aria-hidden>
              <Icon src={kvHeader} alt="key visual" auto />
            </KVIcon>
            <LazyImage
              cover
              src={image}
              alt={imageAlt}
              objectPosition="50% 80%"
            />
            <ButtonWrapper>
              <Button
                secondary
                onClick={() =>
                  matchesLG
                    ? scrollToSection('#search-3d-anchor')
                    : scrollToSection('#flats')
                }
              >
                Zobacz mieszkania
              </Button>
            </ButtonWrapper>
            <LogoWrapper>
              <Icon src={logoBig} alt="logo" auto />
            </LogoWrapper>
          </ImageWrapper>
        </Content>
      </InnerWrapper>
      <Content id="about">
        <ContentWrapper>
          <Heading>O inwestycji</Heading>
          <Description dangerouslySetInnerHTML={{ __html: description }} />
        </ContentWrapper>
      </Content>
    </Wrapper>
  );
};

Header.propTypes = {
  image: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ).isRequired,
  image2: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ).isRequired,
  imageAlt: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export default Header;
