import React from 'react';
import styled from 'styled-components';
import Content from 'components/shared/Content';
import AskForm from './AskForm';

const Wrapper = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  margin: 40px 0;
`;

const SectionAnchor = styled.span`
  position: absolute;
  left: 0;
  top: -130px;
`;

const AskAboutFlat = () => {
  return (
    <Wrapper>
      <SectionAnchor id="form-top" />
      <Content>
        <AskForm />
      </Content>
    </Wrapper>
  );
};

export default AskAboutFlat;
