/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Section from 'components/shared/Section';
import Content from 'components/shared/Content';
import Button from 'components/shared/Button';
import LazyImage from 'components/shared/LazyImage';
import Icon from 'components/shared/Icon';
import deerPDF from 'assets/Ulotka Deer Design Matexi.pdf';
import smartPDF from 'assets/Smart_Life_2021_web.pdf';
import modernPDF from 'assets/Modern_Life_2021_web.pdf';
import highPDF from 'assets/High_Life_2021_web.pdf';
import regulaminPDF from 'assets/REGULAMIN-AMBASADOR.pdf';
import pdfIcon from 'assets/icons/pdf.png';

const Wrapper = styled.article`
  display: flex;
  flex-direction: column;
  ${({ theme }) => theme.mq.xl} {
    flex-direction: row;
    justify-content: space-between;
  }
`;

const Description = styled.p`
  color: ${({ theme }) => theme.colors.gray};
  line-height: 2.2rem;
`;

const ImageWrapper = styled.div`
  width: 100%;
  height: 100%;
  max-width: 800px;
  ${({ theme }) => theme.mq.s} {
    min-height: 410px;
    min-width: 500px;
    max-width: 600px;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  ${({ theme }) => theme.mq.xl} {
    margin-top: -20px;
    margin-left: 60px;
    max-width: 640px;
  }
`;

const StyledColumn = styled(Column)`
  margin-top: 20px;
  margin-left: 0;
`;

const ListItem = styled(Description)`
  position: relative;
  display: flex;
  text-decoration: none;
  margin-bottom: 7px;
  flex-direction: column;
  padding-left: 15px;
  ${({ theme }) => theme.mq.s} {
    padding-left: 0;
    flex-direction: row;
    align-items: center;
  }
  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 10px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #80808a;
    ${({ theme }) => theme.mq.s} {
      position: static;
      top: auto;
      left: auto;
      margin-right: 10px;
    }
  }
  a {
    color: ${({ theme }) => theme.colors.blue};
    font-weight: 700;
    ${({ theme }) => theme.mq.s} {
      margin-left: 8px;
    }
  }
`;

const ButtonWrapper = styled.div`
  margin: 15px 0;
  & > * {
    max-width: max-content;
  }
`;

const PDFLink = styled.a`
  margin-top: 20px;
  display: flex;
  align-items: center;
  color: #1fb0e5;
  font-size: 1.8rem;
  text-decoration: none;
  span {
    margin-left: 15px;
  }
`;

const Heading = styled.h2`
  font-size: 2.3rem;
  line-height: normal;
  font-weight: ${({ theme }) => theme.bold};
  color: ${({ theme }) => theme.colors.blue};
  margin-bottom: 15px;
  ${({ theme }) => theme.mq.s} {
    font-size: 2.6rem;
  }
  ${({ theme }) => theme.mq.xl} {
    margin-top: 40px;
  }
`;

const About2 = ({ image, description, packets }) => (
  <Section id="details">
    <Content>
      <Wrapper>
        <ImageWrapper>
          <LazyImage src={image} alt="image" cover />
        </ImageWrapper>
        <Column>
          <Heading>Wykończenia</Heading>
          <Description dangerouslySetInnerHTML={{ __html: description }} />
          <StyledColumn>
            {packets.map((packet, index) => {
              const pdf = deerPDF;
              // if (index === 0) pdf = smartPDF;
              // if (index === 1) pdf = modernPDF;
              // if (index === 2) pdf = highPDF;
              if (!pdf) return null;
              return (
                <ListItem as="div" key={index}>
                  <p dangerouslySetInnerHTML={{ __html: packet }} />
                  <a target="_blank" href={pdf}>
                    (Pobierz katalog PDF)
                  </a>
                </ListItem>
              );
            })}
          </StyledColumn>
          {/* <ButtonWrapper>
            <Button as="a" href="tel:+48 883 555 647">
              Zadzwoń +48 883 555 647
            </Button>
          </ButtonWrapper> */}
          <PDFLink href={regulaminPDF} target="_blank">
            <Icon src={pdfIcon} alt="pdf" size={40} />
            <span>Regulamin AMBASADOR Deer design</span>
          </PDFLink>
        </Column>
      </Wrapper>
    </Content>
  </Section>
);

About2.propTypes = {
  packets: PropTypes.arrayOf(PropTypes.string).isRequired,
  image: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ).isRequired,
  description: PropTypes.string.isRequired,
};

export default About2;
