import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import styled from 'styled-components';
import Icon from 'components/shared/Icon';
import Content from 'components/shared/Content';
import matexiLogo from 'assets/icons/logo-matexi.svg';
import igIcon from 'assets/icons/instagram.svg';
import fbIcon from 'assets/icons/facebook.svg';
import linkedinIcon from 'assets/icons/linkedin.svg';
import ytIcon from 'assets/icons/youtube.svg';
import twitterIcon from 'assets/icons/twitter.svg';

const Wrapper = styled.footer`
  color: ${({ theme }) => theme.colors.blue};
  background: ${({ theme }) => theme.colors.white};
`;

const InnerWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 30px 0;
  ${({ theme }) => theme.mq.md} {
    flex-direction: row;
    justify-content: space-between;
  }
`;

const SocialMedia = styled.div`
  display: flex;
  align-items: center;
  span {
    display: none;
    margin-right: 10px;
    font-size: 2.1rem;
    font-weight: ${({ theme }) => theme.light};
    ${({ theme }) => theme.mq.xs} {
      display: block;
    }
    ${({ theme }) => theme.mq.s} {
      font-size: 2.4rem;
    }
  }
`;

const Logo = styled.a`
  order: -1;
  width: 210px;
  margin-bottom: 35px;
  ${({ theme }) => theme.mq.md} {
    margin-bottom: 0;
    order: 1;
  }
`;

const SocialMediaItem = styled.a`
  display: block;
  margin: 0 5px;
`;

const getIcon = name => {
  switch (name.toLowerCase()) {
    case 'instagram':
      return igIcon;
    case 'facebook':
      return fbIcon;
    case 'linkedin':
      return linkedinIcon;
    case 'youtube':
      return ytIcon;
    case 'twitter':
      return twitterIcon;
    default:
      return null;
  }
};

const Footer = () => {
  const {
    wpgraphql: {
      page: { PageHome },
    },
  } = useStaticQuery(graphql`
    query querySocialMedia {
      wpgraphql {
        page(id: "/strona-glowna/", idType: URI) {
          PageHome {
            contactSm {
              contactSmTitle
              contactSmUrl
            }
          }
        }
      }
    }
  `);

  const socialMedia = PageHome.contactSm.map(
    ({ contactSmTitle, contactSmUrl }) => ({
      icon: getIcon(contactSmTitle),
      alt: contactSmTitle,
      link: contactSmUrl,
    })
  );

  return (
    <Wrapper>
      <Content>
        <InnerWrapper>
          <SocialMedia>
            <span>Znajdź nas:</span>
            {socialMedia.map(({ icon, alt, link }) => (
              <SocialMediaItem target="_blank" href={link} key={alt}>
                <Icon size={42} src={icon} alt={alt} />
              </SocialMediaItem>
            ))}
          </SocialMedia>
          <Logo href="https://www.matexipolska.pl" target="_blank">
            <Icon full src={matexiLogo} alt="logo matexi" />
          </Logo>
        </InnerWrapper>
      </Content>
    </Wrapper>
  );
};

export default Footer;
