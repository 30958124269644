import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Section from 'components/shared/Section';
import Content from 'components/shared/Content';
import Icon from 'components/shared/Icon';
import LazyImage from 'components/shared/LazyImage';
import kvIcon from 'assets/icons/kvGallery.svg';
import cameraIcon from 'assets/icons/aparat.svg';
import Swiper from 'components/Gallery/Swiper';
import 'swiper/swiper-bundle.css';
import LazyLoad from 'react-lazyload';

const StyledSection = styled(Section)`
  border-top: 40px solid ${({ theme }) => theme.colors.gray100};
`;

const HeadingWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 80px;
  background: ${({ theme }) => theme.colors.green};
  padding-left: 30px;
  margin-bottom: 20px;
`;

const Heading = styled.h2`
  font-size: ${({ theme }) => theme.fontSize.xl};
  font-weight: ${({ theme }) => theme.light};
  color: ${({ theme }) => theme.colors.white};
  text-transform: uppercase;
  margin-left: 20px;
`;

const HeadingInner = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  flex-basis: 100%;
  ${({ theme }) => theme.mq.s} {
    flex-basis: 50%;
  }
`;

const ImageWrapper = styled.div`
  display: none;
  flex-basis: 50%;
  height: 100%;
  ${({ theme }) => theme.mq.s} {
    display: block;
  }
`;

const IconWrapper = styled.figure`
  width: 100px;
  height: 80px;
  position: absolute;
  top: 0;
  right: -38px;
  z-index: 1;
  pointer-events: none;
`;

const InnerWrapper = styled.div`
  width: 100%;
`;

const Album = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 0 0;
`;

const Title = styled.p`
  font-weight: ${({ theme }) => theme.light};
  padding: 20px 0 15px;
  line-height: 2.2rem;
  text-align: center;
`;

const Gallery = ({ image, albums, heading }) => (
  <StyledSection id="gallery">
    <LazyLoad offset={500} height="100vh" once>
      <Content>
        <HeadingWrapper>
          <HeadingInner>
            <Icon src={cameraIcon} alt="aparat" size={50} />
            <Heading>{heading}</Heading>
            <IconWrapper>
              <Icon src={kvIcon} alt="key visual" full />
            </IconWrapper>
          </HeadingInner>
          <ImageWrapper>
            <LazyImage src={image} alt="forest" cover />
          </ImageWrapper>
        </HeadingWrapper>
        <InnerWrapper>
          {albums.map(({ title, images }) => (
            <Album key={title}>
              <Title>{title}</Title>
              <Swiper perView={4} images={images} />
            </Album>
          ))}
        </InnerWrapper>
      </Content>
    </LazyLoad>
  </StyledSection>
);

Gallery.propTypes = {
  image: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ).isRequired,
  albums: PropTypes.arrayOf(PropTypes.object).isRequired,
  heading: PropTypes.string.isRequired,
};

export default Gallery;
